import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
export default defineComponent({
    name: 'main-category-form',
    emits: ['toggleSelection'],
    watch: {
        searchCategory: {
            immediate: true,
            handler(value) {
                this.onSearchCategory(value);
            }
        }
    },
    props: {
        categories: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            searchCategory: '',
            list: [],
            originalCategories: []
        };
    },
    created() {
        this.list = this.categories;
        this.originalCategories = this.categories;
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        toggleSelection(category) {
            this.$emit('toggleSelection', category);
        },
        onSearchCategory(value) {
            const keyword = value;
            this.list = this.originalCategories;
            const search = this.list.filter((category) => {
                const { poLabel } = category;
                return poLabel.toLowerCase().indexOf(keyword) > -1;
            });
            this.list = search;
        }
    }
});
