import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
export default defineComponent({
    name: 'sub-category-selection',
    emits: ['toggleForm'],
    props: {
        categories: {
            type: Array,
            required: true
        },
        selectedCategoryIds: {
            type: Array,
            required: true,
        },
        savedSubCategoryIds: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            list: [],
            selectedCategories: [],
            originalSelectedCategories: [],
            selectedCategory: null,
            selectedSubCategoryIds: []
        };
    },
    async created() {
        this.list = this.categories;
        this.getSelectedCategories();
        if (!this.selectedCategory) {
            this.setFirstSelection();
        }
    },
    computed: {
        ...mapGetters(USERS_STORE, ['accountSetupCache']),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        ...mapActions(USERS_STORE, ['updateAccountSetupCache']),
        onSelectCategory(category) {
            this.selectedCategory = category;
            this.setSubCategorySelection();
        },
        getSelectedCategories() {
            const { selectedCategoryIds, list } = this;
            this.selectedCategories = list.filter((category) => selectedCategoryIds.includes(category.id));
            this.originalSelectedCategories = { ...this.selectedCategories };
        },
        setFirstSelection() {
            const { list, selectedCategoryIds } = this;
            const result = list.find((category) => selectedCategoryIds.includes(category.id));
            this.selectedCategory = result;
            this.setSubCategorySelection();
        },
        setSubCategorySelection() {
            const { selectedCategory, accountSetupCache, savedSubCategoryIds } = this;
            const dataCache = accountSetupCache || {};
            const { subCategories } = selectedCategory;
            const savedIds = savedSubCategoryIds || [];
            let cachedIds = [];
            if (dataCache && dataCache.selectedSubCategoryIds) {
                cachedIds = dataCache.selectedSubCategoryIds;
            }
            this.selectedSubCategoryIds = cachedIds.concat(savedIds);
            subCategories.forEach((subCategory) => {
                if (cachedIds.includes(subCategory.id)) {
                    // eslint-disable-next-line no-param-reassign
                    subCategory.selected = true;
                }
            });
        },
        onSelectSubCategory(id) {
            const { selectedCategory, selectedSubCategoryIds, accountSetupCache } = this;
            const { subCategories } = selectedCategory;
            subCategories.forEach((subCategory) => {
                if (subCategory.id === id) {
                    // eslint-disable-next-line no-param-reassign
                    subCategory.selected = !subCategory.selected;
                    if (subCategory.selected) {
                        selectedSubCategoryIds.push(id);
                    }
                    else {
                        const index = selectedSubCategoryIds.indexOf(id);
                        if (index > -1) {
                            selectedSubCategoryIds.splice(index, 1);
                        }
                    }
                }
            });
            accountSetupCache.selectedSubCategoryIds = selectedSubCategoryIds;
            this.updateAccountSetupCache(accountSetupCache);
        }
    }
});
