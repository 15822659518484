import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
import MainCategoryForm from './MainCategoryForm.vue';
import SubCategorySelectionForm from './SubCategorySelection.vue';
export default defineComponent({
    name: 'category-form',
    components: {
        MainCategoryForm,
        SubCategorySelectionForm
    },
    data() {
        return {
            categories: [],
            mainSelection: true,
            selectedCategoryIds: [],
            savedSubCategoryIds: [],
            hasSelection: false,
            submittingForm: false,
            hasCategoryLoaded: false,
        };
    },
    async created() {
        this.categories = await this.getCategories();
        const userSkills = await this.getUserSkills(this.user.id);
        if (userSkills.length) {
            this.setSavedCategorySelection(userSkills);
        }
        else {
            this.setCacheCategorySelection();
        }
        this.hasCategoryLoaded = true;
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'accountSetupCache']),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        ...mapActions(USERS_STORE, [
            'updateUser',
            'updateUserAccountSetupCache',
            'updateAccountSetupCache',
            'saveUserSkills',
            'getUserSkills'
        ]),
        onBackButton() {
            this.mainSelection = !this.mainSelection;
        },
        toggleForm() {
            if (!this.mainSelection) {
                this.updateSkills();
            }
            else {
                this.mainSelection = !this.mainSelection;
                this.updateUserAccountSetupCache();
            }
        },
        toggleSelection(category) {
            const { selectedCategoryIds, accountSetupCache } = this;
            const categoryId = category.id;
            // eslint-disable-next-line no-param-reassign
            category.selected = !category.selected;
            if (category.selected && !selectedCategoryIds.includes(categoryId)) {
                selectedCategoryIds.push(categoryId);
            }
            else {
                const index = selectedCategoryIds.indexOf(categoryId);
                if (index > -1) {
                    selectedCategoryIds.splice(index, 1);
                }
            }
            this.hasSelection = selectedCategoryIds.length > 0;
            accountSetupCache.selectedCategoryIds = selectedCategoryIds;
            this.updateAccountSetupCache(accountSetupCache);
        },
        setCacheCategorySelection() {
            const { categories, accountSetupCache } = this;
            if (accountSetupCache && 'selectedCategoryIds' in accountSetupCache) {
                this.selectedCategoryIds = accountSetupCache.selectedCategoryIds;
                const newCategories = categories.map((category) => {
                    if (this.selectedCategoryIds.includes(category.id)) {
                        // eslint-disable-next-line no-param-reassign
                        category.selected = true;
                    }
                    return category;
                });
                this.categories = newCategories;
                this.hasSelection = this.selectedCategoryIds.length > 0;
            }
        },
        setSavedCategorySelection(userSkills) {
            const { categories, savedSubCategoryIds } = this;
            const list = [];
            const storedCategoryIds = [];
            const isSelected = true;
            userSkills.forEach((userSkill) => {
                const { subCategory } = userSkill;
                const { category } = subCategory;
                const categoryId = category.id;
                category.selected = isSelected;
                if (!storedCategoryIds.includes(categoryId)) {
                    category.subCategories = [];
                    category.subCategories.push(subCategory);
                    list.push(category);
                    storedCategoryIds.push(categoryId);
                }
                else {
                    const foundCategory = list.filter((item) => item.id === categoryId);
                    foundCategory[0].subCategories.push(subCategory);
                }
                savedSubCategoryIds.push(subCategory.id);
            });
            const newCategories = categories.map((category) => {
                const { subCategories } = category;
                const categoryId = category.id;
                let newCategory = category;
                if (storedCategoryIds.includes(categoryId)) {
                    const foundCategory = list.filter((item) => item.id === categoryId);
                    [newCategory] = foundCategory;
                    newCategory.subCategories = subCategories.map((subCategory) => {
                        const foundSubCategory = foundCategory[0].subCategories.filter((item) => item.id === subCategory.id);
                        if (foundSubCategory.length) {
                            // eslint-disable-next-line no-param-reassign
                            subCategory.selected = isSelected;
                        }
                        return subCategory;
                    });
                }
                return newCategory;
            });
            this.selectedCategoryIds = storedCategoryIds;
            this.hasSelection = list.length > 0;
            this.categories = newCategories;
        },
        updateSkills() {
            const { categories, selectedCategoryIds, accountSetupCache } = this;
            const selectedSubCategoriesIds = accountSetupCache.selectedSubCategoryIds || this.savedSubCategoryIds;
            const selectedCategories = categories.filter((item) => selectedCategoryIds.includes(item.id));
            let subCategoryIds = [];
            const hasSubCategoryIds = selectedSubCategoriesIds && selectedSubCategoriesIds.length;
            if (hasSubCategoryIds) {
                subCategoryIds = subCategoryIds.concat(selectedSubCategoriesIds);
            }
            selectedCategories.forEach((category) => {
                const { subCategories } = category;
                let hasSubSelected = false;
                if (hasSubCategoryIds) {
                    for (let i = 0; i < subCategories.length; i++) {
                        if (selectedSubCategoriesIds.includes(subCategories[i].id)) {
                            hasSubSelected = !hasSubSelected;
                            break;
                        }
                    }
                }
                if (!hasSubSelected) {
                    const allSubCategories = [];
                    subCategories.forEach((item) => {
                        allSubCategories.push(item.id);
                    });
                    subCategoryIds = subCategoryIds.concat(allSubCategories);
                    hasSubSelected = !hasSubSelected;
                }
            });
            this.submittingForm = true;
            this.saveUserSkills({
                userId: this.user.id,
                skills: { subCategoryId: subCategoryIds }
            })
                .then(async () => {
                this.$notify.success({
                    message: 'Skillset successfully saved.'
                });
                this.updateAccountSetupCache(null);
                this.updateUserAccountSetupCache();
                this.updateAccountSetupCache({});
                this.$router.push({ name: 'setup/certification' });
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error saving skillset, please try again.'
                });
            })
                .finally(() => {
                this.submittingForm = false;
            });
        }
    }
});
